<div class="filter-panel">
  <locumsnest-modal
    [show]="true"
    [showCloseButton]="false"
    [title]="'Choose your filters:'"
    (close)="onCloseModal()"
  >
    <ng-container body>
      <div class="body">
        <div class="filter-categories">
          <span class="title">Selected</span>
          <div class="scroller">
            <div
              *ngFor="let filterGroup of filterGroups; trackBy: trackByFilterGroup"
              class="scroller-inside"
            >
              <div class="filter-group" (click)="onFilterGroupClicked(filterGroup)">
                <div class="filter-group--name">
                  <locumsnest-check-box
                    class="check-box"
                    [displayText]="filterGroup.displayName"
                    [theme]="'blue'"
                    [size]="'normal'"
                    [isButton]="true"
                    [ngModel]="true"
                    [isDisabled]="true"
                    [opaque]="selectedFilterGroup === filterGroup.slug"
                  >
                  </locumsnest-check-box>
                </div>
                <div
                  class="filter-group--count"
                  [ngClass]="{
                    'fade-out-on-disabled': selectedFilterGroup !== filterGroup.slug
                  }"
                >
                  ({{ filterGroup.count }})
                </div>
              </div>
              <div
                *ngIf="
                  showDepartmentSection &&
                  filterGroup.slug === 'Profession' &&
                  selectedFilterGroup === 'Profession'
                "
                class="profession-selected"
              >
                <div
                  *ngFor="
                    let filterCategory of getProfessionFilterCategories();
                    trackBy: trackByFilterCategory
                  "
                >
                  <div
                    *ngIf="filterCategory.selected"
                    class="filter-group-selected"
                    (click)="onCategoryClicked(filterCategory)"
                  >
                    <div class="filter-group-selected--name">
                      <locumsnest-check-box
                        class="check-box"
                        [displayText]="filterCategory.displayName"
                        [theme]="'blue'"
                        [size]="'normal'"
                        [isButton]="true"
                        [ngModel]="true"
                        [isDisabled]="true"
                        [opaque]="selectedProfession.id === filterCategory.id"
                      >
                      </locumsnest-check-box>
                    </div>
                    <div
                      class="filter-group-selected--count"
                      [ngClass]="{
                        'fade-out-on-disabled': selectedProfession.id !== filterCategory.id
                      }"
                    >
                      ({{ filterCategory.count }})
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!showDepartmentSection" style="width: 100%">
          <ng-container *ngFor="let filterGroup of filterGroups; trackBy: trackBySlug">
            <ng-container *ngIf="filterGroup.slug === selectedFilterGroup">
              <span class="title">Please choose your {{ filterGroup?.slug }}(s)</span>
              <p class="notes">{{ filterGroup?.notes }}</p>
              <div class="scroller">
                <div class="scroller-inside">
                  <div
                    *ngIf="
                      !showDepartmentSection &&
                      (filterGroup.filterCategories || filterGroup.options) as filterGroups
                    "
                  >
                    <div
                      *ngFor="
                        let filterCategory of filterGroups;
                        trackBy: trackByFilterCategoryOrOption
                      "
                    >
                      <div style="display: flex">
                        <locumsnest-check-box
                          [displayText]="
                            selectedFilterGroup === 'Profession' || selectedFilterGroup === 'Ward'
                              ? filterCategory.displayName
                              : filterCategory.name
                          "
                          [size]="'normal'"
                          [theme]="'blue'"
                          [ngModel]="filterCategory.selected"
                          (checked)="
                            selectedFilterGroup === 'Profession' || selectedFilterGroup === 'Ward'
                              ? onFilterCategorySelected(filterCategory, selectedFilterGroup)
                              : onOptionSelected(filterCategory, 'Site')
                          "
                        >
                        </locumsnest-check-box>
                        <span
                          *ngIf="
                            selectedFilterGroup === 'Ward' &&
                            filterCategory.filterOptions[0].options.length > 1
                          "
                          class="select-all-ward-options"
                          (click)="onFilterCategorySelected(filterCategory, selectedFilterGroup)"
                        >
                          -
                          {{
                            getWardRostersSelected(filterCategory.filterOptions[0].options)
                              ? 'Unselect all'
                              : 'Select all'
                          }}
                        </span>
                      </div>
                      <div
                        *ngIf="
                          selectedFilterGroup === 'Ward' &&
                          filterCategory.filterOptions[0].options.length > 0
                        "
                      >
                        <div
                          *ngFor="
                            let roster of filterCategory.filterOptions[0].options;
                            trackBy: trackByOption
                          "
                          style="margin-left: 15px"
                        >
                          <div style="display: flex">
                            <locumsnest-check-box
                              [displayText]="roster.name"
                              [theme]="'green'"
                              [size]="'normal'"
                              [ngModel]="roster.selected"
                              (checked)="onOptionSelected(roster, 'Roster')"
                            >
                            </locumsnest-check-box>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div>
          <div *ngIf="showDepartmentSection">
            <span *ngIf="selectedFilterGroup === 'Profession'" class="title">
              {{ selectedProfession.title }}: narrow down by department</span
            >
            <span *ngIf="selectedFilterGroup === 'Ward'" class="title">
              {{ selectedWard.name }}: narrow down by roster</span
            >
            <span
              class="select-all-options"
              (click)="
                onToggleProfessionDepartments(
                  !getProfessionDepartmentsSelected(),
                  selectedFilterGroup
                )
              "
            >
              - {{ getProfessionDepartmentsSelected() ? 'Unselect all' : 'Select all' }}
            </span>
          </div>
          <div class="scroller">
            <div
              *ngIf="
                showDepartmentSection &&
                selectedFilterGroup === 'Profession' &&
                getProfessionDepartments().length > 0
              "
              class="scroller-inside"
            >
              <div
                *ngFor="
                  let department of getProfessionDepartments()[0].options;
                  trackBy: trackByOption
                "
              >
                <div style="display: flex">
                  <locumsnest-check-box
                    [displayText]="department.name"
                    [theme]="'blue'"
                    [size]="'normal'"
                    [ngModel]="department.selected"
                    (checked)="onOptionSelected(department, 'Department access')"
                  >
                  </locumsnest-check-box>
                  <span
                    *ngIf="getProfessionSubDepartments(department).length > 1"
                    class="select-all-options"
                    (click)="onOptionSelected(department, 'Department access')"
                  >
                    - {{ department.selected ? 'Unselect all' : 'Select all' }}
                  </span>
                </div>
                <div
                  *ngIf="getProfessionSubDepartments(department).length > 1"
                  style="margin-left: 15px"
                >
                  <div
                    *ngFor="
                      let subDepartment of getProfessionSubDepartments(department);
                      trackBy: trackBySubDepartment
                    "
                  >
                    <locumsnest-check-box
                      [displayText]="subDepartment.name"
                      [theme]="'green'"
                      [size]="'normal'"
                      [ngModel]="subDepartment.selected"
                      (checked)="onOptionSelected(subDepartment, 'Sub-Department access')"
                    >
                    </locumsnest-check-box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container #footer footer>
      <div class="panel-footer">
        <div class="select-all">
          <locumsnest-check-box
            [displayText]="'Select all'"
            [size]="'normal'"
            [ngModel]="canSelectAll"
            [theme]="'blue'"
            [isDisabled]="selectAllInProgress"
            (checked)="onSelectAllClicked($event)"
          >
          </locumsnest-check-box>
          <div *ngIf="selectAllInProgress" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div *ngIf="filterAlert !== ''" class="filter-alert-container">
          <div class="filter-alert">{{ filterAlert }}</div>
        </div>
        <div class="footer-buttons">
          <ng-content #footerButtons></ng-content>
        </div>
      </div>
    </ng-container>
  </locumsnest-modal>
</div>
