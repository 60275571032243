import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'PreferredSpecialtyCategoryEntity'>(
  'PreferredSpecialtyCategoryEntity'
);

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredSpecialtyCategoryTempSignal extends actionableFactory.create<
  'Add Selected Preferred Specialty Category Temp Signal',
  { id: number; professionId: number }
>('Add Selected Preferred Specialty Category Temp Signal') {}

export class RemoveSelectedPreferredSpecialtyCategoryTempSignal extends actionableFactory.create<
  'Remove Selected Preferred Specialty Category Temp Signal',
  { id: number; professionId: number }
>('Remove Selected Preferred Specialty Category Temp Signal') {}

export class SelectAllPreferredSpecialtyCategoriesTempSignal extends actionableFactory.create<
  'Select All Preferred Specialty Categories Temp Signal',
  Record<string, never>
>('Select All Preferred Specialty Categories Temp Signal') {}

export class SelectAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal extends actionableFactory.create<
  'Select All Preferred Specialty Categories For Selected Profession Temp Signal',
  Record<string, never>
>('Select All Preferred Specialty Categories For Selected Profession Temp Signal') {}
export class ClearAllPreferredSpecialtyCategoriesTempSignal extends actionableFactory.create<
  'Clear All Preferred Specialty Categories Temp Signal',
  Record<string, never>
>('Clear All Preferred Specialty Categories Temp Signal') {}
export class ClearAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal extends actionableFactory.create<
  'Clear All Preferred Specialty Categories For Selected Profession Temp Signal',
  Record<string, never>
>('Clear All Preferred Specialty Categories For Selected Profession Temp Signal') {}

export type PreferredSpecialtyCategorySignals = InitializeCollectionSignal;
