import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { every, get, isNil } from 'lodash-es';

import {
  IFilterCategory,
  IFilterCategoryPayload,
  IFilterGroup,
  IFilterSelectAllPayload,
  IOption,
  IOptionPayload,
} from '@locumsnest/components/src/lib/interfaces/filter';

import { IWardEntity } from '../../../interfaces/api/ward-entity';
import { IProfessionEntity } from './../../../interfaces/api/profession-entity';

@Component({
  selector: 'locumsnest-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent {
  @Input() filterGroups: IFilterGroup[];
  @Input() isMatchProduct: boolean;
  @Input() filterGroupHasPreferredProfession: boolean;
  @Input() selectedFilterGroup: string;
  @Input() selectedProfession: IProfessionEntity;
  @Input() selectedWard: IWardEntity;
  @Input() showDepartmentSection: boolean;
  @Input() canSelectAll: boolean;
  @Input() selectAllInProgress: boolean;
  @Input() filterAlert: string;
  @Output() closeModal = new EventEmitter<void>();
  @Output() saveModal = new EventEmitter<void>();
  @Output() optionSelected = new EventEmitter<IOptionPayload>();
  @Output() filterCategorySelected = new EventEmitter<IFilterCategoryPayload>();
  @Output() updateSelectedFilter = new EventEmitter<string>();
  @Output() updateSelectedCategory = new EventEmitter<number>();
  @Output() showHideDepartmentSection = new EventEmitter<void>();
  @Output() selectAllClicked = new EventEmitter<IFilterSelectAllPayload>();
  @Output() selectProfessionDepartments = new EventEmitter<string>();
  @Output() unselectProfessionDepartments = new EventEmitter<string>();

  getFilterGroup() {
    return this.filterGroups
      ? this.filterGroups.filter((x) => x.slug === this.selectedFilterGroup)
      : [];
  }

  getFilterCategoryName() {
    const filterGroup = this.getFilterGroup();
    return filterGroup.length > 0 ? filterGroup[0].slug : null;
  }

  getFilterGroupNotes() {
    const filterGroup = this.getFilterGroup();
    return filterGroup.length > 0 ? filterGroup[0].notes : null;
  }

  getFilterCategories() {
    if (this.selectedFilterGroup === 'Profession') {
      return this.getProfessionFilterCategories();
    }
    if (this.selectedFilterGroup === 'Site') {
      return this.getSiteFilterOptions();
    }
    if (this.selectedFilterGroup === 'Ward') {
      return this.getProfessionFilterCategories();
    }
  }

  getProfessionFilterCategories() {
    const filterGroup = this.getFilterGroup();
    return filterGroup.length > 0 ? filterGroup[0].filterCategories : [];
  }

  getSiteFilterOptions() {
    const filterGroup = this.getFilterGroup();
    return filterGroup.length > 0 ? filterGroup[0].options : [];
  }

  getProfessionDepartments() {
    if (!isNil(this.selectedProfession)) {
      const professionFilterCategories = this.getProfessionFilterCategories();
      return professionFilterCategories && professionFilterCategories.length > 0
        ? professionFilterCategories.filter((x) => x.id === this.selectedProfession.id)[0]
            .filterOptions
        : [];
    }

    return [];
  }

  getProfessionDepartmentsSelected() {
    return every(
      get(this.getProfessionDepartments(), [0, 'options'], []),
      (option) => option.selected
    );
  }

  getWardRostersSelected(options) {
    return options.every((option) => option.selected);
  }

  onToggleProfessionDepartments(select: boolean, selectedFilterGroup: string) {
    if (select) {
      return this.selectProfessionDepartments.emit(selectedFilterGroup);
    }
    return this.unselectProfessionDepartments.emit(selectedFilterGroup);
  }

  getProfessionSubDepartments(department) {
    const departmentsFilterOptions = this.getProfessionDepartments();
    return departmentsFilterOptions[1].options.filter((x) => x.category === department.id);
  }

  onCategoryClicked(filterCategory: IFilterCategory) {
    this.updateSelectedCategory.emit(filterCategory.id);
  }

  onShowDepartmentSection() {
    this.showHideDepartmentSection.emit();
  }

  onSaveModal() {
    this.saveModal.emit();
  }

  onCloseModal() {
    this.closeModal.emit();
  }

  onFilterGroupClicked(filterGroup: IFilterGroup) {
    this.updateSelectedFilter.emit(filterGroup.slug);
  }

  onFilterCategorySelected(category: IFilterCategory, filterGroupName: string) {
    const payload: IFilterCategoryPayload = {
      id: category.id,
      selected: category.selected,
      filterGroupName,
    };

    this.filterCategorySelected.emit(payload);
  }

  onOptionSelected(option: IOption, filterOptionName: string) {
    const payload: IOptionPayload = {
      id: option.id,
      parentId: option.parentId,
      selected: option.selected,
      filterOptionName,
      filterCategoryId:
        filterOptionName === 'Roster' ? this.selectedWard?.id : this.selectedProfession?.id,
      filterGroupName: this.selectedFilterGroup,
    };

    this.optionSelected.emit(payload);
  }

  onSelectAllClicked(event: Event) {
    const payload: IFilterSelectAllPayload = {
      selected: event.currentTarget['checked'],
      filterGroup: this.selectedFilterGroup,
      showDepartmentSection: this.showDepartmentSection,
    };

    this.selectAllClicked.emit(payload);
  }

  trackBySlug(_: number, group: IFilterGroup) {
    return group.slug;
  }

  trackByFilterGroup(index: number, group: IFilterGroup) {
    return group ? `${group['id']}${group.displayName} ${group.namespace}` : index;
  }

  trackByFilterCategory(index: number, category: IFilterCategory) {
    return category.displayName + category.id || index;
  }

  trackByOption(_: number, option: IOption) {
    return option ? option.name + option.parentId : null;
  }

  trackBySubDepartment(index: number, group: IOption) {
    return group?.name + group?.parentId || index;
  }

  trackByFilterCategoryOrOption(index: number) {
    return index;
  }
}
